import { CreditAppState } from '../CreditApp.model';
import { Mutator } from '../types/ContextTypes';
import {
    JointApplicantPayload,
    JointApplicantRelationshipPayload,
    JointEmploymentPayload,
    JointHousingPayload,
    LandingPagePayload,
    PrimaryApplicantPayload,
    PrimaryEmploymentPayload,
    PrimaryHousingPayload,
    ReviewPayload
} from '../types/PayloadTypes';
import { mapSuffixToOptions } from '../utils/helper';
import { produce } from '../utils/Immer.class';
import { getUrlVars } from '../utils/urlParameters';

export const setApplicantChoice: Mutator<LandingPagePayload, CreditAppState> = (payload, state) => {
    return produce(state, (draft) => {
        draft.applicantChoice = payload.body.applicantSelection;
        const urlParams = getUrlVars();
        if (urlParams.preFillCreditApp !== 'true') {
            if (payload.body.externalData?.offer) {
                const { primaryApplicant } = draft.personalInfo;
                const { primaryApplicant: primaryApplicantApplicantHousingInfo } = draft.housingInfo;
                const { shopper } = payload.body.externalData.offer;
                if (!shopper) return;

                // Primary Applicant
                primaryApplicant.firstName.setValue(shopper.firstName ?? '');
                primaryApplicant.middleName.setValue(shopper.middleName ?? '');
                primaryApplicant.lastName.setValue(shopper.lastName ?? '');
                primaryApplicant.email.setValue(shopper.email ?? '');
                primaryApplicant.phone.setValue(shopper.phone ?? '');
                primaryApplicant.suffix.setValue(
                    shopper.suffix && shopper.suffix !== undefined ? mapSuffixToOptions(shopper.suffix) : ''
                );

                // Primary Applicant Housing
                primaryApplicantApplicantHousingInfo.address.streetAddress.setValue(shopper.streetAddress ?? '');
                primaryApplicantApplicantHousingInfo.address.city.setValue(shopper.city ?? '');
                primaryApplicantApplicantHousingInfo.address.zip.setValue(shopper.zip ?? '');
                primaryApplicantApplicantHousingInfo.address.suiteAptNumber.setValue(shopper.streetAddress2 ?? '');
                primaryApplicantApplicantHousingInfo.address.state.setValue(shopper.state);

                // Disable First and Last Name if prefilled from external offer
                if (shopper.firstName !== '') primaryApplicant.firstName.disabled = true;
                if (shopper.lastName !== '') primaryApplicant.lastName.disabled = true;
            }

            // Co-Applicant
            if (payload.body.externalData?.offer?.coBuyer) {
                const { jointApplicant } = draft.personalInfo;
                const { jointApplicant: jointApplicantHousingInfo } = draft.housingInfo;
                const { coBuyer } = payload.body.externalData.offer;

                // Personal
                jointApplicant.firstName.setValue((coBuyer.firstName ?? '').substring(0, jointApplicant.firstName.maxlength));
                jointApplicant.middleName.setValue((coBuyer.middleName ?? '').substring(0, jointApplicant.middleName.maxlength));
                jointApplicant.lastName.setValue((coBuyer.lastName ?? '').substring(0, jointApplicant.lastName.maxlength));
                jointApplicant.email.setValue(coBuyer.email ?? '');
                jointApplicant.phone.setValue(coBuyer.phone ?? '');
                jointApplicant.suffix.setValue(
                    coBuyer.suffix && coBuyer.suffix !== undefined ? mapSuffixToOptions(coBuyer.suffix) : ''
                );

                // Housing
                jointApplicantHousingInfo.address.streetAddress.setValue(coBuyer.streetAddress ?? '');
                jointApplicantHousingInfo.address.city.setValue(coBuyer.city ?? '');
                jointApplicantHousingInfo.address.zip.setValue(coBuyer.zip ?? '');
                jointApplicantHousingInfo.address.suiteAptNumber.setValue(coBuyer.streetAddress2 ?? '');
                jointApplicantHousingInfo.address.state.setValue(coBuyer.state);
            }
        }
        return;
    });
};

export const submitIndividualApplicantPersonalInfo = (payload: PrimaryApplicantPayload, state: CreditAppState): CreditAppState => {
    return produce(state, (draft) => {
        draft.personalInfo.primaryApplicant = payload.primaryApplicant;
    });
};

export const submitJointApplicantPersonalInfo = (payload: JointApplicantPayload, state: CreditAppState): CreditAppState => {
    return produce(state, (draft) => {
        draft.personalInfo.primaryApplicant = payload.primaryApplicant;
        draft.personalInfo.jointApplicant = payload.jointApplicant;
    });
};

export const submitIndividualHousing = (payload: PrimaryHousingPayload, state: CreditAppState): CreditAppState => {
    return produce(state, (draft) => {
        draft.housingInfo.primaryApplicant = payload.primaryApplicant;
    });
};

export const submitJointHousing = (payload: JointHousingPayload, state: CreditAppState): CreditAppState => {
    return produce(state, (draft) => {
        draft.housingInfo.primaryApplicant = payload.primaryApplicant;
        draft.housingInfo.jointApplicant = payload.jointApplicant;
    });
};

export const submitIndividualEmployment = (payload: PrimaryEmploymentPayload, state: CreditAppState): CreditAppState => {
    return produce(state, (draft) => {
        draft.employmentInfo.primaryApplicant = payload.primaryApplicant;
    });
};

export const submitJointEmployment = (payload: JointEmploymentPayload, state: CreditAppState): CreditAppState => {
    return produce(state, (draft) => {
        draft.employmentInfo.primaryApplicant = payload.primaryApplicant;
        draft.employmentInfo.jointApplicant = payload.jointApplicant;
    });
};

export const submitReview = (payload: ReviewPayload, state: CreditAppState): CreditAppState => {
    return produce(state, (draft) => {
        draft.reviewInfo = payload.review;
    });
};

export const setJointApplicantRelationship = (
    payload: JointApplicantRelationshipPayload,
    state: CreditAppState
): CreditAppState => {
    return produce(state, (draft) => {
        draft.personalInfo.jointApplicant.relationship.value = payload.value;
        draft.personalInfo.jointApplicant.relationship.disabled = payload.disabled;
    });
};
