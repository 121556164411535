import React, { FC, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { HorizontalRule } from '@makemydeal/ui-bricks/dist/cox';

import { submitIndividualEmployment, submitJointEmployment } from '../../actions/CreditAppMutators';
import LoaderOverlay from '../../components/LoaderOverlay';
import { ButtonWrapper, PageContainer } from '../../components/shared.styled';
import WithPageUI from '../../components/WithPageUI';
import { useCreditAppDispatch, useCreditAppState } from '../../contexts/CreditAppContext';
import { useCreditAppExperience } from '../../contexts/CreditAppExperienceContext';
import { useExternalResourceState } from '../../contexts/ExternalResourceContext';
import { useCreditFilter } from '../../customHooks/useCreditFilter';
import { useSubmit } from '../../customHooks/UseSubmit.hook';
import { APPLICANT_CHOICE, PAGES } from '../../types/Constants';
import { Resources } from '../../types/ExternalResources';
import { CustomValidator, YearAndMonthValidation } from '../../utils/customValidation';
import { PreviousYearMonthAgeValidation, YearMonthAgeValidation } from '../../utils/customValidation/YearAndMonthValidation';
import { getUrlVars } from '../../utils/urlParameters';
import { EmploymentDispatcher } from './Employment.dispatcher';
import EmploymentInfoForm from './EmploymentInfoForm';
import { Employed } from './models/employmentStatus/Employed';
import { PreviouslyEmployed } from './models/employmentStatus/previousStatus/PreviouslyEmployed';

interface Consumer {
    type: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumber?: string;
    dob?: string;
    ssn?: string;
    incomeAmount?: number;
    incomeFrequency?: string;
    streetAddress1?: string;
    streetAddress2?: string;
    city?: string;
    state?: string;
    zipCode?: string;
}

interface PrequalData {
    dealXgId?: string;
    dealXgVersionId?: string;
    sourcePartnerId: string;
    sourcePartnerDealerId: string;
    consumers: Consumer[];
}

const EmploymentInfoPage: React.FC<{ location: Location }> = ({ location }) => {
    const state = useCreditAppState();
    const externalResourcesState = useExternalResourceState();
    const sendPrequalCheck = (externalResourcesState as Resources)?.toggles?.toggles?.sendPrequalCheck;
    const dispatch = useCreditAppDispatch();
    const { isStandaloneExperience, isUCAEmbeddedExperience } = useCreditAppExperience();

    const isJoint = state.applicantChoice === APPLICANT_CHOICE.JOINT;

    const history = useHistory();
    const [primaryApplicantEmployment, setPrimaryApplicant] = useState(state.employmentInfo?.primaryApplicant);
    const [jointApplicantEmployment, setJointApplicant] = useState(state.employmentInfo?.jointApplicant);

    const primaryApplicantDispatcher = useMemo(() => new EmploymentDispatcher(setPrimaryApplicant), []);
    const jointApplicantDispatcher = useMemo(() => new EmploymentDispatcher(setJointApplicant), []);
    const enableEmploymentTimeVsAgeValidation = (externalResourcesState as Resources)?.toggles?.toggles
        ?.enableEmploymentAgeValidation;

    const handleRoute = () => {
        history.push(PAGES['/review'].urlPath);
    };

    const primaryApplicantAgeMonths = state.personalInfo.primaryApplicant.currentApplicantAgeMonths();
    const jointApplicantAgeMonths = state.personalInfo.jointApplicant.currentApplicantAgeMonths();
    const formName = 'employment';

    const primaryApplicant = state.personalInfo?.primaryApplicant;
    const jointApplicant = state.personalInfo?.jointApplicant;
    const housingInfo = state.housingInfo;
    const incomeFrequency = 'Monthly';

    const dealXgId = (externalResourcesState as Resources)?.dealXgId;
    const dealXgVersionId = (externalResourcesState as Resources)?.offer?.version;

    let yearAndMonthValidationPrimary: CustomValidator;
    let yearAndMonthValidationJoint: CustomValidator;
    let yearAndMonthValidationPreviousPrimary: CustomValidator;
    let yearAndMonthValidationPreviousJoint: CustomValidator;
    // Year and month Validations
    if (enableEmploymentTimeVsAgeValidation) {
        yearAndMonthValidationPrimary = new YearMonthAgeValidation(
            (primaryApplicantEmployment.employmentStatus as Employed)?.yearsEmployed?.value,
            (primaryApplicantEmployment.employmentStatus as Employed)?.monthsEmployed?.value,
            primaryApplicantAgeMonths,
            formName
        );
        yearAndMonthValidationJoint = new YearMonthAgeValidation(
            (jointApplicantEmployment.employmentStatus as Employed)?.yearsEmployed?.value,
            (jointApplicantEmployment.employmentStatus as Employed)?.monthsEmployed?.value,
            jointApplicantAgeMonths,
            formName
        );

        yearAndMonthValidationPreviousPrimary = new PreviousYearMonthAgeValidation(
            (primaryApplicantEmployment.employmentStatus as Employed)?.yearsEmployed?.value,
            (primaryApplicantEmployment.employmentStatus as Employed)?.monthsEmployed?.value,
            primaryApplicantAgeMonths,
            ((primaryApplicantEmployment.employmentStatus as Employed)
                ?.previousEmployment as PreviouslyEmployed)?.previousYearsEmployed?.value,
            ((primaryApplicantEmployment.employmentStatus as Employed)
                ?.previousEmployment as PreviouslyEmployed)?.previousMonthsEmployed?.value,
            formName
        );
        yearAndMonthValidationPreviousJoint = new PreviousYearMonthAgeValidation(
            (jointApplicantEmployment.employmentStatus as Employed)?.yearsEmployed?.value,
            (jointApplicantEmployment.employmentStatus as Employed)?.monthsEmployed?.value,
            jointApplicantAgeMonths,
            ((jointApplicantEmployment.employmentStatus as Employed)
                ?.previousEmployment as PreviouslyEmployed)?.previousYearsEmployed?.value,
            ((jointApplicantEmployment.employmentStatus as Employed)
                ?.previousEmployment as PreviouslyEmployed)?.previousMonthsEmployed?.value,
            formName
        );
    } else {
        yearAndMonthValidationPrimary = new YearAndMonthValidation(
            (primaryApplicantEmployment.employmentStatus as Employed)?.yearsEmployed?.value,
            (primaryApplicantEmployment.employmentStatus as Employed)?.monthsEmployed?.value
        );
        yearAndMonthValidationPreviousPrimary = new YearAndMonthValidation(
            ((primaryApplicantEmployment.employmentStatus as Employed)
                ?.previousEmployment as PreviouslyEmployed)?.previousYearsEmployed?.value,
            ((primaryApplicantEmployment.employmentStatus as Employed)
                ?.previousEmployment as PreviouslyEmployed)?.previousMonthsEmployed?.value
        );

        yearAndMonthValidationJoint = new YearAndMonthValidation(
            (jointApplicantEmployment.employmentStatus as Employed)?.yearsEmployed?.value,
            (jointApplicantEmployment.employmentStatus as Employed)?.monthsEmployed?.value
        );
        yearAndMonthValidationPreviousJoint = new YearAndMonthValidation(
            ((jointApplicantEmployment.employmentStatus as Employed)
                ?.previousEmployment as PreviouslyEmployed)?.previousYearsEmployed?.value,
            ((jointApplicantEmployment.employmentStatus as Employed)
                ?.previousEmployment as PreviouslyEmployed)?.previousMonthsEmployed?.value
        );
    }

    const SubmitIndividual = () => {
        const statePayload = {
            primaryApplicant: {
                state: primaryApplicantEmployment,
                setter: setPrimaryApplicant,
                customValidators: [yearAndMonthValidationPrimary, yearAndMonthValidationPreviousPrimary]
            }
        };
        useSubmit(dispatch, submitIndividualEmployment, statePayload, handleRoute, location, isStandaloneExperience);
    };

    const SubmitJoint = () => {
        const statePayload = {
            primaryApplicant: {
                state: primaryApplicantEmployment,
                setter: setPrimaryApplicant,
                customValidators: [yearAndMonthValidationPrimary, yearAndMonthValidationPreviousPrimary]
            },
            jointApplicant: {
                state: jointApplicantEmployment,
                setter: setJointApplicant,
                customValidators: [yearAndMonthValidationJoint, yearAndMonthValidationPreviousJoint]
            }
        };
        useSubmit(dispatch, submitJointEmployment, statePayload, handleRoute, location, isStandaloneExperience);
    };
    const Submit = () => {
        if (isJoint) SubmitJoint();
        else SubmitIndividual();
    };

    const urlParams = getUrlVars();
    const [sourcePartnerId, setSourcePartnerId] = React.useState('');
    const [sourcePartnerDealerId, setSourcePartnerDealerId] = React.useState('');
    if (!sourcePartnerId) {
        if (urlParams.sourcePartnerId) {
            setSourcePartnerId(urlParams.sourcePartnerId);
        }
    }

    if (!sourcePartnerDealerId) {
        if (urlParams.sourcePartnerDealerId) {
            setSourcePartnerDealerId(urlParams.sourcePartnerDealerId);
        }
    }
    const prequalData: PrequalData = {
        dealXgId,
        dealXgVersionId,
        sourcePartnerId,
        sourcePartnerDealerId,
        consumers: []
    };

    prequalData.consumers.push({
        type: 'applicant',
        firstName: primaryApplicant.firstName.value,
        lastName: primaryApplicant.lastName.value,
        email: primaryApplicant.email.value,
        phoneNumber: primaryApplicant.phone.value,
        dob: primaryApplicant.dob.value,
        ssn: primaryApplicant.ssn.value,
        incomeAmount: parseFloat((primaryApplicantEmployment.employmentStatus as Employed)?.monthlyIncome?.value || '0'),
        incomeFrequency,
        streetAddress1: housingInfo?.primaryApplicant?.address?.streetAddress?.value,
        streetAddress2: housingInfo?.primaryApplicant?.address?.suiteAptNumber?.value,
        city: housingInfo?.primaryApplicant?.address?.city?.value,
        state: housingInfo?.primaryApplicant?.address?.state?.value,
        zipCode: housingInfo?.primaryApplicant?.address?.zip?.value
    });

    if (isJoint) {
        prequalData.consumers.push({
            type: 'coApplicant',
            firstName: jointApplicant.firstName.value,
            lastName: jointApplicant.lastName.value,
            email: jointApplicant.email.value,
            phoneNumber: jointApplicant.phone.value,
            dob: jointApplicant.dob.value,
            ssn: jointApplicant.ssn.value,
            incomeAmount: parseFloat((jointApplicantEmployment.employmentStatus as Employed)?.monthlyIncome?.value || '0'),
            incomeFrequency,
            streetAddress1: housingInfo?.jointApplicant?.address?.streetAddress?.value,
            streetAddress2: housingInfo?.jointApplicant?.address?.suiteAptNumber?.value,
            city: housingInfo?.jointApplicant?.address?.city?.value,
            state: housingInfo?.jointApplicant?.address?.state?.value,
            zipCode: housingInfo?.jointApplicant?.address?.zip?.value
        });
    }

    const { creditFilter, showLoaderOverlay, responseDelay } = useCreditFilter(
        prequalData,
        isUCAEmbeddedExperience && sendPrequalCheck,
        Submit
    );

    return (
        <div>
            {showLoaderOverlay && <LoaderOverlay showMessage={responseDelay} />}
            <PageContainer>
                <EmploymentInfoForm
                    className="primary-applicant"
                    title={'Primary Applicant'}
                    employment={primaryApplicantEmployment}
                    employmentDispatchers={primaryApplicantDispatcher}
                    isJoint={isJoint}
                    enableEmploymentTimeVsAgeValidation={enableEmploymentTimeVsAgeValidation}
                    yearMonthPickerErrorMessage={yearAndMonthValidationPrimary.getErrorMessage()}
                    previousYearMonthPickerErrorMessage={yearAndMonthValidationPreviousPrimary.getErrorMessage()}
                />
                {isJoint && (
                    <>
                        <HorizontalRule />
                        <EmploymentInfoForm
                            title={'Co-Applicant'}
                            className="co-applicant"
                            employment={jointApplicantEmployment}
                            employmentDispatchers={jointApplicantDispatcher}
                            isJoint
                            enableEmploymentTimeVsAgeValidation={enableEmploymentTimeVsAgeValidation}
                            yearMonthPickerErrorMessage={yearAndMonthValidationJoint.getErrorMessage()}
                            previousYearMonthPickerErrorMessage={yearAndMonthValidationPreviousJoint.getErrorMessage()}
                        />
                    </>
                )}
                <ButtonWrapper onClick={creditFilter}>Next</ButtonWrapper>
            </PageContainer>
        </div>
    );
};

export default WithPageUI(EmploymentInfoPage);
