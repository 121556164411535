import { rem } from 'polished';
import styled from 'styled-components';

import { Button, HorizontalRule } from '@makemydeal/ui-bricks/dist/cox';

import { devices } from '../utils/deviceUtils';

export const CommonHeader = styled.div`
    font-weight: ${({ theme }) => theme.headerFontWeight};
    font-family: ${({ theme }) => theme.headerFontFamily};
    text-transform: ${({ theme }) => theme.headerTextTransform};
    color: ${({ theme }) => theme.primaryFontColor};
`;

export const PageContainer = styled.div`
    background-color: ${({ theme }) => theme.creditAppPageContainerBackgroundColor};
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-grow: 1;
    @media ${devices.desktop && devices.desktopL} {
        justify-content: flex-start;
        height: auto;
    }
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    @media ${devices.mobileXL} {
        flex-direction: column;
    }
`;

export const Column = styled.div`
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    margin-left: ${rem(10)};
    margin-right: ${rem(10)};
    .align-field-mobile select,
    input {
        @media ${devices.tablet} {
            min-width: 380px;
        }
        @media ${devices.mobileXL} {
            min-width: 360px;
        }
        @media ${devices.mobileL} {
            min-width: 340px;
        }
        @media ${devices.mobileM} {
            min-width: 320px;
        }
        @media ${devices.mobile} {
            min-width: 300px;
        }
    }
`;

// For some reason, the button style was NOT catching the desktop breaks,
// so had to use a specific query for mobile to get the button size to work
export const ButtonWrapper = styled(Button)`
    display: flex;
    justify-content: center;
    margin: ${rem(40)} auto 0 auto;
    width: 12.5rem;
    @media ${devices.mobile && devices.mobileM && devices.mobileL && devices.mobileXL} {
        width: 100%;
    }
`;

export const TitleWrapper = styled.h3`
    margin-left: ${rem(10)};
    @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
        font-size: ${rem(18)};
    }

    @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
        font-size: ${rem(18)};
    }
`;

export const ErrorMessageWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: inherit;
`;

export const DisclaimerText = styled.div`
    padding-left: ${rem(10)};
    color: ${({ theme }) => theme.creditAppDisclaimerTextColor};
    font-size: ${rem(12.8)};
    margin-top: ${({ className }) => (className === 'additionalIncomeDisclaimer' ? 0 : rem(5))};
    margin-left: ${({ className }) => (className === 'additionalIncomeDisclaimer' ? 0 : rem(10))};
    margin-bottom: ${({ className }) => (className === 'additionalIncomeDisclaimer' ? rem(30) : 0)};
`;

export const CreditDecisionDisclaimerText = styled.div`
    color: ${({ theme }) => theme.creditAppDisclaimerTextColor};
    text-align: center;
    margin: ${rem(24.0)} 0;
    font-size: ${({ className }) => (className === 'credit-decision-disclaimer-review' ? rem(12) : rem(16))};
`;

export const StyledHorizontalRule = styled(HorizontalRule)`
    margin-bottom: ${rem(30)};
`;

export const CheckboxWrapper = styled.div`
    margin: 1.5rem 0 1.6875rem 0;
    margin-left: 0.625rem;
    text-align: justify;
`;
export const CheckboxWrapperForCheckbox = styled.div`
    margin: 1.5rem 0 0 0;
    align-items: baseline;
    justify-content: flex - start;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    & div {
        a,
        label {
            font-size: 0.9rem !important;
            white-space: normal;
            label {
                color: ${(props) => (props.color ? '#C3032F' : undefined)};
            }
        }
    }
    & span {
        font-size: 0.9rem !important;
        white-space: normal;
        color: ${(props) => (props.color ? '#C3032F' : undefined)};
    }
    & > * {
        margin-right: 0.3rem;
    }
    @media ${devices.desktop && devices.desktopL} {
        font-size: 0.9rem;
        text-align: justify;
        & div {
            a,
            label {
                font-weight: normal;
                font-size: 0.9rem;
                white-space: nowrap;
            }
        }
        & span {
            font-size: 0.9rem;
            white-space: normal;
        }
    }
`;
export const ErrorMessageContainer = styled.div`
    margin: 1rem 0 0 0;
    align-items: center;
    justify-content: flex - start;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    & div {
        a,
        label {
            font-size: 0.75rem !important;
            white-space: nowrap;
        }
    }
    & span {
        font-size: 0.75rem !important;
        white-space: nowrap;
    }
    & > * {
        margin-right: 0.3rem;
    }
    @media ${devices.desktop && devices.desktopL} {
        font-size: 0.9rem;
        text-align: justify;
        & div {
            a,
            label {
                font-weight: normal;
                font-size: 0.9rem;
                white-space: nowrap;
            }
        }
        & span {
            font-size: 0.9rem;
            white-space: nowrap;
        }
    }
`;

export const ErrorMsgWrapperForPrivacyNoticeIndicator = styled.div`
    color: #c3032f;
    margin-left: 1rem;
`;

export const ErrorMsgWrapperForDisclaimerBIndicator = styled.div`
    color: #c3032f;
    margin-left: 1rem;
`;

export const SpinnerContainer = styled.div`
    height: ${rem(500)};
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const SubmitButton = styled(Button as any)`
    display: flex;
    justify-content: center;
    margin: ${rem(25)} auto;
    width: 12.5rem;
    @media ${devices.mobile && devices.mobileM && devices.mobileL && devices.mobileXL} {
        width: 100 %;
    }
    background-color: ${({ theme, status }) => {
        if (status === 'default') return theme.primaryButtonColor;
        if (status === 'sending') return theme.primaryButtonColor;
        if (status === 'success') return '#00b900';
    }} !important;

    :hover {
        background-color: ${({ theme, status }) => {
            if (status === 'default') return theme.primaryButtonHoverColor;
            if (status === 'success') return '#00b900';
        }};
    }
`;

export const ThemedInputWrapper = styled.div`
    position: relative;
    input,
    select,
    option {
        font-family: ${({ theme }) => theme.primaryFontFamily};
    }
    @media (max-width: 480px) {
        .inputFormFieldTooltipModal .tooltip-text-wrapper {
            left: 250%;
            &:after {
                left: 30%;
            }
        }
    }
`;

export const InputIcon = styled.div`
    position: absolute;
    top: 16px;
    right: 20px;
    left: auto;
    color: #636569;
`;

export const LoaderOverlayContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    opacity: 0.9;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9999;

    .loadMessage {
        font-size: 1rem;
        color: ${({ theme }) => theme.primaryFontColor};
        text-align: center;
        line-height: 2rem;
    }
`;
