import { FormField, Validator } from '../../../formValidator';
import { findKeyFromValue } from '../../../utils/helper';
import { Clonable } from '../../../utils/Immer.class';
import { validateMortgage, validateOwnRent } from '../../../utils/Validation';
import { Cloned, InfoCardData } from '../../Interfaces';
import { OwnershipTypes } from './../../../utils/selectOptions';
import { Address } from './Address.model';
import { Spouse } from './Spouse.model';

export class Housing extends Validator implements Clonable<Housing> {
    ownOrRent: FormField.Select;
    address: Address;
    monthlyPayment: FormField.Input;
    previousAddress?: Address;
    addressSameAsPrimaryApplicant: FormField.Checkbox;
    spouse?: Spouse;

    constructor(me?: Cloned<Housing>) {
        super();
        this.ownOrRent =
            me?.ownOrRent ??
            new FormField.Select({
                name: 'ownOrRent',
                placeholder: 'Select Ownership',
                label: 'Do You Own or Rent?',
                customRequiredLabel: 'Please make a selection.',
                optional: false,
                selectOptions: OwnershipTypes,
                validationFunction: validateOwnRent
            });
        this.address = me?.address ?? new Address();
        this.monthlyPayment =
            me?.monthlyPayment ??
            new FormField.Input({
                name: 'monthlyPayment',
                placeholder: 'e.g. 1500',
                label: 'Monthly Mortgage/ Rent',
                customRequiredLabel: 'Amount is required.',
                optional: false,
                errorMessage: 'Invalid. The number must be less than 100,000',
                validationFunction: validateMortgage
            });

        this.previousAddress = me?.previousAddress ?? undefined;
        this.addressSameAsPrimaryApplicant =
            me?.addressSameAsPrimaryApplicant ??
            new FormField.Checkbox({
                value: 'false',
                name: 'addressSameAsPrimaryApplicant',
                label: 'Is your address the same as the primary applicant',
                isChecked: false,
                errorMessage: '',
                validationFunction: () => true
            });
        this.spouse = me?.spouse ?? undefined;
    }

    static totalMonthsLivingAtSameAddress = (address: Address | undefined): any => {
        if (address) return Number(address.yearsAtAddress.value) * 12 + Number(address.monthsAtAddress.value);
        else return null;
    };

    clone = (): Housing => new Housing({ ...this });

    reviewAdapter = (): InfoCardData => {
        const infoCardData: InfoCardData = {
            ownOrRent: {
                label: this.ownOrRent.label ?? '',
                value: findKeyFromValue(this.ownOrRent.value, OwnershipTypes) ?? ''
            }
        };

        if (this.ownOrRent.value !== OwnershipTypes['Own Outright']) {
            infoCardData.monthlyPayment = {
                label: this.monthlyPayment.label ?? '',
                value: `$${this.ownOrRent.value === OwnershipTypes['Own Outright'] ? '0' : this.monthlyPayment.value}`
            };
        }
        infoCardData.addressAndSteNumber = {
            label: 'Current Address',
            value:
                `${this.address.streetAddress.value}${
                    this.address.suiteAptNumber.value ? `, ${this.address.suiteAptNumber.value}` : ''
                }` ?? ''
        };
        infoCardData.cityStateZip = {
            label: '',
            value: `${this.address.city.value}, ${this.address.state.value}, ${this.address.zip.value}`
        };
        if (this.address.county?.value) {
            infoCardData.county = {
                label: '',
                value: `${this.address.county.value}`
            };
        }
        infoCardData.yearsAtAddress = {
            label: 'Have You Lived Here Over 2 Years?',
            value: this.previousAddress ? 'No' : 'Yes'
        };

        if (this.previousAddress) {
            infoCardData.previousAddressAndSteNumber = {
                label: 'Previous Address',
                value:
                    `${this.previousAddress.streetAddress.value}${
                        this.previousAddress.suiteAptNumber.value ? `, ${this.previousAddress.suiteAptNumber.value}` : ''
                    }` ?? ''
            };
            infoCardData.previousCityStateZip = {
                label: '',
                // eslint-disable-next-line max-len
                value: `${this.previousAddress?.city.value}, ${this.previousAddress?.state.value}, ${this.previousAddress?.zip.value}`
            };
            if (this.previousAddress.county?.value) {
                infoCardData.previousAddresscounty = {
                    label: '',
                    value: `${this.previousAddress?.county?.value}`
                };
            }
        }

        if (this.spouse) {
            infoCardData.spouseName = {
                label: 'Spouse Name',
                value: `${this.spouse.firstName.value}${this.spouse.middleName.value ? ` ${this.spouse.middleName.value} ` : ' '}${
                    this.spouse.lastName.value
                }${this.spouse.suffix.value ? ` ${this.spouse.suffix.value}` : ''}`
            };
            infoCardData.spouseAddressAndSteNumber = {
                label: 'Spouse Address',
                value:
                    `${this.spouse.address.streetAddress.value}${
                        this.spouse.address.suiteAptNumber.value ? `, ${this.spouse.address.suiteAptNumber.value}` : ''
                    }` ?? ''
            };
            infoCardData.spouseCityStateZip = {
                label: '',
                // eslint-disable-next-line max-len
                value: `${this.spouse.address?.city.value}, ${this.spouse.address?.state.value}, ${this.spouse.address?.zip.value}`
            };
            if (this.spouse.address?.county?.value) {
                infoCardData.spouseAddresscounty = {
                    label: '',
                    value: `${this.spouse.address?.county?.value}`
                };
            }
        }
        return infoCardData;
    };
}
