import axios from 'axios';
import React, { useCallback } from 'react';

import { sendUCAQualificationEvent, sendUCAQualificationTimeoutEvent } from '../utils/eventUtils';
import { getLambdaUrl } from '../utils/externalUrls';
import { isEmptyObject } from '../utils/helper';

export const useCreditFilter = (prequalData: any, checkPrequal: boolean, Submit: () => void) => {
    /* Only if the checkPrequal param returns true then we send the data to the prequal endpoint
    /* Otherwise we just submit the credit app normally */
    const [prequalResponse, setPrequalResponse] = React.useState({});
    const [showLoaderOverlay, setShowLoaderOverlay] = React.useState(true);
    const [responseDelay, setResponseDelay] = React.useState(true);

    // Ref to track if the component is mounted
    const isMountedRef = React.useRef(true);

    React.useEffect(() => {
        isMountedRef.current = true; // Mark as mounted
        return () => {
            isMountedRef.current = false; // Mark as unmounted
        };
    }, []);

    React.useEffect(() => {
        // Hide overlay if the request has returned a response
        setShowLoaderOverlay(!isEmptyObject(prequalResponse));
    }, [prequalResponse]);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            if (isMountedRef.current) {
                setResponseDelay(showLoaderOverlay);
            }
        }, 3000); // After 3 seconds, show message to wait for 10 seconds
        return () => clearTimeout(timeout);
    });

    const creditFilter = useCallback(() => {
        if (checkPrequal) {
            setShowLoaderOverlay(true);
            axios
                .post(
                    `${getLambdaUrl()}/prequal`,
                    {
                        dealXgId: prequalData.dealXgId,
                        dealXgVersionId: prequalData.dealXgVersionId,
                        sourcePartnerDealerId: prequalData.sourcePartnerDealerId,
                        sourcePartnerId: prequalData.sourcePartnerId,
                        consumers: prequalData.consumers
                    },
                    {
                        // request will returns error and timeout after 10 seconds
                        timeout: 10000
                    }
                )
                .then((resp) => {
                    if (resp.status === 200 && resp.data.body.creditFilter) {
                        // If response returns 200 status and creditFilter object is present
                        const qualificationsResponse = {
                            status: resp.data.body.creditFilter.status,
                            message: resp.data.body.creditFilter.message,
                            sourcePartnerID: prequalData.sourcePartnerId,
                            sourcePartnerDealerID: prequalData.sourcePartnerDealerId,
                            dealXgId: prequalData.dealXgId,
                            dealXgVersionId: prequalData.dealXgVersionId,
                            qualifications: resp.data.body.creditFilter.qualifications || []
                        };
                        sendUCAQualificationEvent(qualificationsResponse);
                        setTimeout(() => {
                            if (isMountedRef.current) {
                                setPrequalResponse(resp.data.body.creditFilter);
                                Submit();
                            }
                        }, 800); // Delay to show overlay and hide upon receiving response
                    } else {
                        // If response is not 200 or creditFilter object is not present
                        const qualificationsErrorResponse = {
                            sourcePartnerID: prequalData.sourcePartnerId,
                            sourcePartnerDealerID: prequalData.sourcePartnerDealerId,
                            dealXgId: prequalData.dealXgId,
                            dealXgVersionId: prequalData.dealXgVersionId,
                            status: resp.statusText,
                            message: resp.statusText
                        };
                        sendUCAQualificationEvent(qualificationsErrorResponse);
                        setPrequalResponse({ result: 'Request Failed.' });
                        Submit();
                    }
                })
                .catch((error) => {
                    if (error.code === 'ECONNABORTED') {
                        // If request times out
                        const qualificationsTimeoutResponse = {
                            sourcePartnerID: prequalData.sourcePartnerId,
                            sourcePartnerDealerID: prequalData.sourcePartnerDealerId,
                            dealXgId: prequalData.dealXgId,
                            dealXgVersionId: prequalData.dealXgVersionId
                        };
                        sendUCAQualificationTimeoutEvent(qualificationsTimeoutResponse);
                    } else {
                        // If request fails for any other reason
                        const qualificationsErrorResponse = {
                            sourcePartnerID: prequalData.sourcePartnerId,
                            sourcePartnerDealerID: prequalData.sourcePartnerDealerId,
                            dealXgId: prequalData.dealXgId,
                            dealXgVersionId: prequalData.dealXgVersionId,
                            status: error.response?.statusText || 'Error',
                            message: error.message
                        };
                        sendUCAQualificationEvent(qualificationsErrorResponse);
                    }
                    if (isMountedRef.current) {
                        setPrequalResponse({ result: 'Request Failed.' });
                        Submit();
                    }
                });
        } else {
            Submit();
        }
    }, [checkPrequal, prequalData, Submit]);

    return { creditFilter, showLoaderOverlay, responseDelay };
};
