/* eslint-disable prefer-const */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { HorizontalRule } from '@makemydeal/ui-bricks/dist/cox';

import {
    setApplicantChoice,
    setJointApplicantRelationship,
    submitIndividualHousing,
    submitJointHousing
} from '../../actions/CreditAppMutators';
import LabelWithModal from '../../components/LabelWithModal/LabelWithModal';
import { ButtonWrapper, PageContainer } from '../../components/shared.styled';
import WithPageUI from '../../components/WithPageUI';
import { useCreditAppDispatch, useCreditAppState } from '../../contexts/CreditAppContext';
import { useCreditAppExperience } from '../../contexts/CreditAppExperienceContext';
import { useExternalResourceState } from '../../contexts/ExternalResourceContext';
import { useFetchCityState } from '../../customHooks/useFetchCityState.hook';
import { useSubmit } from '../../customHooks/UseSubmit.hook';
import { APPLICANT_CHOICE, PAGES } from '../../types/Constants';
import { Resources } from '../../types/ExternalResources';
import { CustomValidator, YearAndMonthValidation } from '../../utils/customValidation';
import { PreviousYearMonthAgeValidation, YearMonthAgeValidation } from '../../utils/customValidation/YearAndMonthValidation';
import { ZipAndStateValidation } from '../../utils/customValidation/ZipAndStateValidation';
import { AddressDispatcher } from './Address.Dispatcher';
import { HousingDispatcher } from './Housing.dispatcher';
import HousingInfoForm from './HousingInfoForm';
import { SpouseDispatcher } from './Spouse.Dispatcher';
import SpouseInfoForm from './SpouseInfoForm';
import { WisconsinMarriedDisclaimerContent } from './WisconsinMarriedDisclaimerContent';

const HousingInfoPage: React.FC<{ location: Location }> = ({ location }) => {
    const state = useCreditAppState();
    const externalResourcesState = useExternalResourceState();
    const { isStandaloneExperience, isUCAEmbeddedExperience } = useCreditAppExperience();

    const dispatch = useCreditAppDispatch();
    const history = useHistory();

    const isJoint = state.applicantChoice === APPLICANT_CHOICE.JOINT;

    const enableShortenedCommunityPropertyFlow = (externalResourcesState as Resources)?.toggles?.toggles
        ?.enableShortenedCommunityPropertyFlow;
    const enableHousingTimeVsAgeValidation = (externalResourcesState as Resources)?.toggles?.toggles?.enableHousingAgeValidation;
    const addSuffixField = (externalResourcesState as Resources)?.toggles?.toggles?.addSuffixField;

    const [primaryApplicant, setPrimaryApplicant] = useState(state.housingInfo?.primaryApplicant);
    const primaryApplicantDispatcher = React.useMemo(() => new HousingDispatcher(setPrimaryApplicant), []);
    const primaryAddressDispatcher = React.useMemo(() => new AddressDispatcher(setPrimaryApplicant, 'address'), []);
    const primarySpouseDispatcher = React.useMemo(() => new SpouseDispatcher(setPrimaryApplicant, primaryApplicant), [
        primaryApplicant
    ]);
    const primaryPreviousAddressDispatcher = React.useMemo(() => new AddressDispatcher(setPrimaryApplicant, 'previousAddress'), []);

    const [jointApplicant, setJointApplicant] = useState(state.housingInfo?.jointApplicant);
    const jointApplicantDispatcher = new HousingDispatcher(setJointApplicant, primaryApplicant);
    const jointAddressDispatcher = React.useMemo(() => new AddressDispatcher(setJointApplicant, 'address'), []);
    const jointSpouseDispatcher = React.useMemo(() => new SpouseDispatcher(setJointApplicant, jointApplicant), [jointApplicant]);
    const jointPreviousAddressDispatcher = React.useMemo(() => new AddressDispatcher(setJointApplicant, 'previousAddress'), []);

    const primaryApplicantAge = state.personalInfo.primaryApplicant.currentApplicantAge();
    const jointApplicantAge = state.personalInfo.jointApplicant.currentApplicantAge();
    const primaryApplicantAgeMonths = state.personalInfo.primaryApplicant.currentApplicantAgeMonths();
    const jointApplicantAgeMonths = state.personalInfo.jointApplicant.currentApplicantAgeMonths();

    let yearAndMonthValidationPrimary: CustomValidator;
    let yearAndMonthValidationJoint: CustomValidator;
    let previousYearAndMonthValidationPrimary: CustomValidator;
    let previousYearAndMonthValidationJoint: CustomValidator;
    const formName = 'address';
    // Year and month Validations
    if (enableHousingTimeVsAgeValidation) {
        yearAndMonthValidationPrimary = new YearMonthAgeValidation(
            primaryApplicant.address.yearsAtAddress.value,
            primaryApplicant.address.monthsAtAddress.value,
            primaryApplicantAgeMonths,
            formName
        );
        yearAndMonthValidationJoint = new YearMonthAgeValidation(
            jointApplicant.address.yearsAtAddress.value,
            jointApplicant.address.monthsAtAddress.value,
            jointApplicantAgeMonths,
            formName
        );

        previousYearAndMonthValidationPrimary = new PreviousYearMonthAgeValidation(
            primaryApplicant.address.yearsAtAddress.value,
            primaryApplicant.address.monthsAtAddress.value,
            primaryApplicantAgeMonths,
            primaryApplicant.previousAddress?.yearsAtAddress?.value,
            primaryApplicant.previousAddress?.monthsAtAddress?.value,
            formName
        );
        previousYearAndMonthValidationJoint = new PreviousYearMonthAgeValidation(
            jointApplicant.address.yearsAtAddress.value,
            jointApplicant.address.monthsAtAddress.value,
            jointApplicantAgeMonths,
            jointApplicant.previousAddress?.yearsAtAddress?.value,
            jointApplicant.previousAddress?.monthsAtAddress?.value,
            formName
        );
    } else {
        yearAndMonthValidationPrimary = new YearAndMonthValidation(
            primaryApplicant.address.yearsAtAddress.value,
            primaryApplicant.address.monthsAtAddress.value
        );
        yearAndMonthValidationJoint = new YearAndMonthValidation(
            jointApplicant.address.yearsAtAddress.value,
            jointApplicant.address.monthsAtAddress.value
        );

        previousYearAndMonthValidationPrimary = new YearAndMonthValidation(
            primaryApplicant.previousAddress?.yearsAtAddress?.value,
            primaryApplicant.previousAddress?.monthsAtAddress?.value
        );
        previousYearAndMonthValidationJoint = new YearAndMonthValidation(
            jointApplicant.previousAddress?.yearsAtAddress?.value,
            jointApplicant.previousAddress?.monthsAtAddress?.value
        );
    }

    // eslint-disable-next-line max-len
    // State and Zip validation
    const [{ data: primaryData }] = useFetchCityState(primaryApplicant.address.zip.value);
    const zipValidationPrimary = new ZipAndStateValidation(primaryData, primaryApplicant.address.state.value);
    const [{ data: jointData }] = useFetchCityState(jointApplicant.address.zip.value);
    const zipValidationJoint = new ZipAndStateValidation(jointData, jointApplicant.address.state.value);
    const [{ data: primarySpouseData }] = useFetchCityState(primaryApplicant.spouse?.address.zip.value);
    const zipValidationPrimarySpouse = new ZipAndStateValidation(primarySpouseData, primaryApplicant.spouse?.address.state.value);
    const [{ data: jointSpouseData }] = useFetchCityState(jointApplicant.spouse?.address.zip.value);
    const zipValidationJointSpouse = new ZipAndStateValidation(jointSpouseData, jointApplicant.spouse?.address.state.value);

    const displayCommunityPropertyModal =
        !enableShortenedCommunityPropertyFlow &&
        state.personalInfo?.primaryApplicant?.maritalStatus?.value === 'Married' &&
        state.housingInfo?.primaryApplicant?.address?.state?.value === 'WI' &&
        state.applicantChoice === APPLICANT_CHOICE.INDIVIDUAL;

    let showSpouseForm: boolean;
    showSpouseForm =
        enableShortenedCommunityPropertyFlow &&
        state.personalInfo?.primaryApplicant?.maritalStatus?.value === 'Married' &&
        state.personalInfo?.jointApplicant?.relationship?.value !== 'Spouse' &&
        primaryApplicant?.address?.state?.value === 'WI';

    let showJointSpouseForm: boolean;
    showJointSpouseForm =
        enableShortenedCommunityPropertyFlow &&
        state.personalInfo?.jointApplicant?.maritalStatus?.value === 'Married' &&
        state.personalInfo?.jointApplicant?.relationship?.value !== 'Spouse' &&
        jointApplicant?.address?.state?.value === 'WI';

    useEffect(() => {
        if (showSpouseForm) {
            primarySpouseDispatcher.modifySpouseModel('add');
        } else {
            primarySpouseDispatcher.modifySpouseModel('remove');
        }
        // eslint-disable-next-line
    }, [showSpouseForm]);

    useEffect(() => {
        if (showJointSpouseForm) {
            jointSpouseDispatcher.modifySpouseModel('add');
        } else {
            jointSpouseDispatcher.modifySpouseModel('remove');
        }
        // eslint-disable-next-line
    }, [showJointSpouseForm]);

    const handleRoute = () => {
        history.push(PAGES['/employment'].urlPath);
    };

    const handleRouteToPersonal = () => {
        dispatch({
            mutator: setApplicantChoice,
            payload: {
                body: { applicantSelection: APPLICANT_CHOICE.JOINT }
            }
        });

        dispatch({
            mutator: setJointApplicantRelationship,
            payload: {
                value: 'Spouse',
                disabled: true
            }
        });

        history.push(PAGES['/personal'].urlPath);
    };

    const SubmitIndividual = () => {
        const customValidators = [yearAndMonthValidationPrimary, previousYearAndMonthValidationPrimary, zipValidationPrimary];
        if (primaryApplicant.spouse) {
            customValidators.push(zipValidationPrimarySpouse);
        }
        const statePayload = {
            primaryApplicant: {
                state: primaryApplicant,
                setter: setPrimaryApplicant,
                customValidators
            }
        };
        useSubmit(dispatch, submitIndividualHousing, statePayload, handleRoute, location, isStandaloneExperience);
    };
    const SubmitJoint = () => {
        const customValidators = [yearAndMonthValidationPrimary, previousYearAndMonthValidationPrimary, zipValidationPrimary];
        if (primaryApplicant.spouse) {
            customValidators.push(zipValidationPrimarySpouse);
        }
        if (jointApplicant.spouse) {
            customValidators.push(zipValidationJointSpouse);
        }
        const statePayload = {
            primaryApplicant: {
                state: primaryApplicant,
                setter: setPrimaryApplicant,
                customValidators
            },
            jointApplicant: {
                state: jointApplicant,
                setter: setJointApplicant,
                customValidators: [yearAndMonthValidationJoint, previousYearAndMonthValidationJoint, zipValidationJoint]
            }
        };
        useSubmit(dispatch, submitJointHousing, statePayload, handleRoute, location, isStandaloneExperience);
    };
    const Submit = () => {
        if (isJoint) SubmitJoint();
        else SubmitIndividual();
    };

    return (
        <PageContainer>
            <HousingInfoForm
                title="Primary Applicant"
                className="primary-applicant"
                housing={primaryApplicant}
                housingDispatcher={primaryApplicantDispatcher}
                addressDispatcher={primaryAddressDispatcher}
                previousAddressDispatcher={primaryPreviousAddressDispatcher}
                yearMonthPickerErrorMessage={yearAndMonthValidationPrimary.getErrorMessage()}
                previousYearMonthPickerErrorMessage={previousYearAndMonthValidationPrimary.getErrorMessage()}
                isPrimary
                isJoint={isJoint}
                applicantAge={primaryApplicantAge}
                zipStateErrorMessage={zipValidationPrimary.getErrorMessage()}
                isUCAEmbeddedExperience={isUCAEmbeddedExperience}
            />
            {primaryApplicant.spouse && (
                <>
                    <HorizontalRule />
                    <SpouseInfoForm
                        title="Spouse"
                        className="spouse"
                        spouse={primaryApplicant.spouse}
                        spouseDispatcher={primarySpouseDispatcher}
                        zipStateErrorMessage={zipValidationPrimarySpouse.getErrorMessage()}
                        isUCAEmbeddedExperience={isUCAEmbeddedExperience}
                        addSuffixField={addSuffixField}
                    />
                </>
            )}
            {isJoint && (
                <>
                    <HorizontalRule />
                    <HousingInfoForm
                        title="Co-Applicant"
                        className="co-applicant"
                        housing={jointApplicant}
                        housingDispatcher={jointApplicantDispatcher}
                        addressDispatcher={jointAddressDispatcher}
                        previousAddressDispatcher={jointPreviousAddressDispatcher}
                        yearMonthPickerErrorMessage={yearAndMonthValidationJoint.getErrorMessage()}
                        previousYearMonthPickerErrorMessage={previousYearAndMonthValidationJoint.getErrorMessage()}
                        isJoint
                        applicantAge={jointApplicantAge}
                        zipStateErrorMessage={zipValidationJoint.getErrorMessage()}
                        isUCAEmbeddedExperience={isUCAEmbeddedExperience}
                    />
                </>
            )}
            {jointApplicant.spouse && (
                <>
                    <HorizontalRule />
                    <SpouseInfoForm
                        title="Spouse"
                        className="co-applicant-spouse"
                        isCoApplicant={true}
                        spouse={jointApplicant.spouse}
                        spouseDispatcher={jointSpouseDispatcher}
                        zipStateErrorMessage={zipValidationJointSpouse.getErrorMessage()}
                        isUCAEmbeddedExperience={isUCAEmbeddedExperience}
                        addSuffixField={addSuffixField}
                    />
                </>
            )}
            {displayCommunityPropertyModal && (
                <LabelWithModal
                    isOpen={true}
                    linkLabel=""
                    isCtaButton={true}
                    modalTitleLabel="Co-Applicant information required"
                    closeButtonLabel="Add Co-applicant"
                    content={WisconsinMarriedDisclaimerContent}
                    handleClick={handleRouteToPersonal}
                />
            )}
            <ButtonWrapper onClick={Submit}>Next</ButtonWrapper>
        </PageContainer>
    );
};

export default WithPageUI(HousingInfoPage);
