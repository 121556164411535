/* eslint-disable max-len */
import { CreditAppState } from '../../CreditApp.model';
import { AdditionalIncome } from '../../pages/EmploymentInfo/models/AdditionalIncome.model';
import { Employment } from '../../pages/EmploymentInfo/models/Employment.model';
import { Employed } from '../../pages/EmploymentInfo/models/employmentStatus/Employed';
import { Housing } from '../../pages/HousingInfo/models/Housing.model';
import { Applicant } from '../../pages/PersonalInfo/models/Applicant.model';
import { APPLICANT_CHOICE } from '../../types/Constants';
import { communityPropertyStates } from '../../utils/communityPropertyStates';
import { GetRoundNumberFromString, totalMonth } from '../../utils/helper';
import { emptyString } from '../../utils/helper';
import { Review } from './../../pages/ReviewPage/models/Review.model';
import { EmploymentStatusSelectOptions } from './../../utils/selectOptions';

export const jointAppFromNonCPState = (creditAppState: CreditAppState): boolean => {
    const { applicantChoice } = creditAppState;
    const primaryApplicantProvincialState = creditAppState.housingInfo.primaryApplicant.address.state.getTrimmed();
    return applicantChoice === APPLICANT_CHOICE.JOINT && !communityPropertyStates.includes(primaryApplicantProvincialState);
};

export const jointAppNonSpousalFromCPState = (creditAppState: CreditAppState): boolean => {
    const { applicantChoice } = creditAppState;
    const primaryApplicantProvincialState = creditAppState.housingInfo.primaryApplicant.address.state.getTrimmed();
    const primaryApplicantMaritalStatus = creditAppState.personalInfo.primaryApplicant.maritalStatus.getTrimmed();
    const coApplicantRelationship = creditAppState.personalInfo.jointApplicant.relationship.getTrimmed();
    return (
        applicantChoice === APPLICANT_CHOICE.JOINT &&
        communityPropertyStates.includes(primaryApplicantProvincialState) &&
        (primaryApplicantMaritalStatus === 'Married' || primaryApplicantMaritalStatus === 'Unmarried') &&
        coApplicantRelationship !== 'Spouse'
    );
};

const getCommunityPropertyDisclosureIndicator = (creditAppState: CreditAppState): boolean => {
    const { housingInfo, personalInfo, applicantChoice } = creditAppState;
    const coApplicantRelationship = personalInfo.jointApplicant.relationship.getTrimmed();
    const primaryApplicantProvincialState = housingInfo.primaryApplicant.address.state.getTrimmed();
    const cpsDisclosureIndicatorValue = communityPropertyStates.includes(primaryApplicantProvincialState);
    if (applicantChoice === APPLICANT_CHOICE.JOINT) {
        return cpsDisclosureIndicatorValue && coApplicantRelationship === 'Spouse';
    }
    return cpsDisclosureIndicatorValue;
};

const applicantHasRegulationBIndicator = (creditAppState: CreditAppState): boolean => {
    const regulationBIndicatorValue = jointAppFromNonCPState(creditAppState) || jointAppNonSpousalFromCPState(creditAppState);

    return regulationBIndicatorValue;
};

/**
 * Is spouse info provided?
 * For community property states spouse info must be provided.
 * This can be provided (on the housing page) without having to fill out a full Joint Application
 */
const isSpouseProvided = (housingInfo: Housing, ucaEmbedded: boolean) => {
    if (!housingInfo?.spouse?.firstName?.getTrimmed()) {
        return false;
    }
    if (!housingInfo?.spouse?.lastName?.getTrimmed()) {
        return false;
    }
    if (!housingInfo?.spouse?.address?.streetAddress?.getTrimmed()) {
        return false;
    }
    if (!housingInfo?.spouse?.address?.city?.getTrimmed()) {
        return false;
    }
    if (!housingInfo?.spouse?.address?.state?.getTrimmed()) {
        return false;
    }
    if (!housingInfo?.spouse?.address?.zip?.getTrimmed()) {
        return false;
    }
    if (ucaEmbedded && !housingInfo?.spouse?.address?.county?.getTrimmed()) {
        return false;
    }

    return true;
};

const nonEmploymentStatus = [
    EmploymentStatusSelectOptions.Unemployed,
    EmploymentStatusSelectOptions.Retired,
    EmploymentStatusSelectOptions['Retired Military']
];

const generateApplicantPayload = (
    applicant: Applicant,
    housing: Housing,
    employment: Employment,
    review: Review,
    extraData?: any
) => {
    const employmentStatus = employment.employmentStatus as Employed | any;
    const ucaEmbedded = extraData?.ucaEmbedded || false;
    const mappedObject: any = {
        // salutation: '', // 'Mr.'  [RESERVED FOR FUTURE USE].
        firstName: applicant.firstName.getTrimmed(), // 'Earnest',
        lastName: applicant.lastName.getTrimmed(), // 'Winhoffer',
        // middleName: '', // 'T',  Optional
        // suffix: '', // 'JR',  Optional
        phone: applicant.phone.getTrimmed(), // '5167349659',
        ssn: applicant.ssn.getTrimmed(), // '788999009',
        dateOfBirth: applicant.dob.getTrimmed(), // '1963-06-18', YYYY-MM-DD,
        address: {
            line1: housing.address.streetAddress.getTrimmed(), // '8374 Test St',
            line2: housing.address.suiteAptNumber.getTrimmed(), // 'APT #D19',  Optional
            city: housing.address.city.getTrimmed(), // 'Littleton',
            state: housing.address.state.getTrimmed() ?? '', // 'CO',
            postalCode: housing.address.zip.getTrimmed() // '80128'
        },
        // driversLicenseNumber: '', // '784534987', Optional
        // driversLicenseState: '', // 'CO', Node Mandatory
        monthsAtCurrentAddress: Housing.totalMonthsLivingAtSameAddress(housing.address), // 18,
        // otherPhone: '', // '5167349659', Optional
        email: applicant.email.getTrimmed(), // 'test_email@testserver.com',
        // preferredContactMethod: '', // 'Email' -  Optional [RESERVED FOR FUTURE USE].
        // preferredLanguage: '', // 'English', Optional
        currentEmployment: {
            employerName: employmentStatus?.employerName?.getTrimmed() ?? '', // 'Boyles Test Co.',
            totalMonthsEmployed: nonEmploymentStatus.includes(employmentStatus?.status?.getTrimmed())
                ? null
                : totalMonth(employmentStatus?.yearsEmployed?.value, employmentStatus?.monthsEmployed?.value), // 36
            occupation: employmentStatus?.occupation?.getTrimmed() ?? '', // 'Program Manager', Optional
            workPhone: employmentStatus?.phone?.getTrimmed() ?? '', // '7130780239',
            status: employmentStatus?.status?.getTrimmed() ?? '' // 'Employed',
        },

        housingStatus: housing.ownOrRent.getTrimmed(), // 'Rent',

        mortgageOrRentAmount: GetRoundNumberFromString(housing.monthlyPayment.getTrimmed()), //  3500.89,
        consentGiven: review.privacyNoticeIndicator.value === 'true'
    };
    if (ucaEmbedded) {
        // Only for UCA Embedded Credit App
        mappedObject.middleName = applicant.middleName?.getTrimmed() ?? '';
        mappedObject.address.county = housing.address.county.getTrimmed() ?? '';
    }
    const isEmployerAddressComplete =
        (!emptyString(employmentStatus?.employerAddress?.getTrimmed()) ||
            !emptyString(employmentStatus?.schoolAddress?.getTrimmed())) &&
        !emptyString(employmentStatus?.city?.getTrimmed()) &&
        !emptyString(employmentStatus?.state?.getTrimmed()) &&
        !emptyString(employmentStatus?.zip?.getTrimmed());
    if (isEmployerAddressComplete) {
        // employerAddress is required if 'isEmployerAddressRequired' dealer setting is enabled, Optional by default
        mappedObject.currentEmployment.employerAddress = {
            line1: employmentStatus?.employerAddress?.getTrimmed() ?? employmentStatus?.schoolAddress?.getTrimmed() ?? '', // '8374 Test St',
            line2: employmentStatus?.suiteNumber?.getTrimmed() ?? '', // 'APT #D19',
            city: employmentStatus?.city?.getTrimmed() ?? '', // 'Littleton',
            state: employmentStatus?.state?.getTrimmed() ?? '', // 'CO',
            postalCode: employmentStatus?.zip?.getTrimmed() ?? '' // '80128',
        };
    }

    const suffix = applicant.suffix.getTrimmed();
    if (!emptyString(suffix) && suffix !== undefined) mappedObject.suffix = suffix;

    const relationship = applicant.relationship.getTrimmed();
    if (!emptyString(relationship)) mappedObject.relationship = relationship;

    // maritalStatus: '', // 'Married', // This is required for a few states like Wisconsin.
    const maritalStatus = applicant.maritalStatus.getTrimmed();
    if (!emptyString(maritalStatus)) mappedObject.maritalStatus = maritalStatus;
    const applicantResidingState = housing.address.state.getTrimmed() ?? '';
    if (applicantResidingState === 'WI' && emptyString(maritalStatus) && !emptyString(relationship) && relationship === 'Spouse') {
        mappedObject.maritalStatus = 'Married';
    }

    // Spouse info for community property states
    if (housing.spouse && isSpouseProvided(housing, ucaEmbedded)) {
        mappedObject.spouse = {
            firstName: housing.spouse.firstName.getTrimmed(),
            lastName: housing.spouse.lastName.getTrimmed(),
            address: {
                line1: housing.spouse.address.streetAddress.getTrimmed(),
                line2: housing.spouse.address.suiteAptNumber.getTrimmed(),
                city: housing.spouse.address.city.getTrimmed(),
                state: housing.spouse.address.state.getTrimmed(),
                postalCode: housing.spouse.address.zip.getTrimmed()
            }
        };
        if (ucaEmbedded) {
            mappedObject.spouse.middleName = housing.spouse.middleName?.getTrimmed() ?? '';
            mappedObject.spouse.address.county = housing.spouse.address.county.getTrimmed() ?? '';
        }
        const spouseSuffix = housing.spouse.suffix.getTrimmed();
        if (!emptyString(spouseSuffix) && spouseSuffix !== undefined) mappedObject.spouse.suffix = spouseSuffix;
    }

    // not mandatory if unemployed
    if (employmentStatus?.status?.getTrimmed() !== EmploymentStatusSelectOptions.Unemployed) {
        const income = GetRoundNumberFromString(employmentStatus?.monthlyIncome?.getTrimmed(), 0);
        mappedObject.income = income === 0 ? null : income;
        mappedObject.incomeFrequency = mappedObject.income === null ? null : 'Monthly'; // Monthly is hardcoded which as matching UI
    }
    if (housing.previousAddress) {
        mappedObject.previousAddress = {
            line1: housing.previousAddress?.streetAddress?.getTrimmed() ?? '', //  '8374 Test St',
            line2: housing.previousAddress?.suiteAptNumber?.getTrimmed() ?? '', // 'APT #D19', Optional
            city: housing.previousAddress?.city?.getTrimmed() ?? '', // 'Littleton',
            state: housing?.previousAddress?.state?.getTrimmed(), // CO
            postalCode: housing.previousAddress?.zip?.getTrimmed() ?? '' // '80128'
        };
        mappedObject.monthsAtPreviousAddress = Housing.totalMonthsLivingAtSameAddress(housing.previousAddress);
        if (ucaEmbedded) {
            mappedObject.previousAddress.county = housing.previousAddress?.county?.getTrimmed() ?? '';
        }
    }

    // monthsAtPreviousAddress: 0, // 18, Optional
    if (employmentStatus.previousEmployment) {
        mappedObject.previousEmployment = {
            employerName: employmentStatus.previousEmployment?.previousEmployerName?.getTrimmed() ?? '', // 'Boyles Test Co.',
            totalMonthsEmployed: nonEmploymentStatus.includes(employmentStatus?.previousEmployment?.status?.getTrimmed())
                ? null
                : totalMonth(
                      employmentStatus.previousEmployment.previousYearsEmployed?.value,
                      employmentStatus.previousEmployment.previousMonthsEmployed?.value
                  ), // 36, Optional
            occupation: employmentStatus.previousEmployment.previousOccupation?.getTrimmed(), // 'Program Manager',
            // workPhone: '', // '7130780239',
            status: employmentStatus.previousEmployment?.status?.getTrimmed() ?? '' // 'Employed',
        };
        const isEmployerAddressComplete =
            (!emptyString(employmentStatus.previousEmployment?.previousEmployerAddress?.getTrimmed()) ||
                !emptyString(employmentStatus.previousEmployment?.previousSchoolAddress?.getTrimmed())) &&
            !emptyString(employmentStatus.previousEmployment?.previousCity?.getTrimmed()) &&
            !emptyString(employmentStatus.previousEmployment?.previousState?.getTrimmed()) &&
            !emptyString(employmentStatus.previousEmployment?.previousZip?.getTrimmed());
        if (isEmployerAddressComplete) {
            // employerAddress is required if 'isEmployerAddressRequired' dealer setting is enabled, Optional by default
            mappedObject.previousEmployment.employerAddress = {
                line1:
                    employmentStatus.previousEmployment?.previousEmployerAddress?.getTrimmed() ??
                    employmentStatus?.previousEmployment?.previousSchoolAddress?.getTrimmed() ??
                    '', // '8374 Test St',
                line2: employmentStatus.previousEmployment?.previousSuiteNumber?.getTrimmed() ?? '', // 'APT #D19',
                city: employmentStatus.previousEmployment?.previousCity?.getTrimmed() ?? '', // 'Littleton',
                state: employmentStatus.previousEmployment?.previousState?.getTrimmed() ?? '', // 'CO',
                postalCode: employmentStatus.previousEmployment?.previousZip?.getTrimmed() ?? '' // '80128',
            };
        }
    }

    // - otherMonthlyIncome and otherMonthlyIncomeSource are mutually conditional, if one is provided the other becomes mandatory.
    const otherMonthlyIncomeSource = AdditionalIncome.getAdditionalSourceOfIncome(employment.additionalIncome);
    const otherMonthlyIncome = AdditionalIncome.getAdditionalMonthlyIncome(employment.additionalIncome);
    if (!emptyString(otherMonthlyIncomeSource) && otherMonthlyIncome !== 0) {
        mappedObject.otherMonthlyIncomeSource = otherMonthlyIncomeSource; // Alimony, Child Support, etc,
        mappedObject.otherMonthlyIncome = Math.ceil(otherMonthlyIncome); // 104.32 -> 105
    }

    return mappedObject;
};

const generatePayload = (creditAppState: CreditAppState, extraData?: any): object => {
    const { personalInfo, housingInfo, employmentInfo, reviewInfo, applicantChoice } = creditAppState;
    const jsonPayload = {
        applicant: generateApplicantPayload(
            personalInfo.primaryApplicant,
            housingInfo.primaryApplicant,
            employmentInfo.primaryApplicant,
            reviewInfo,
            extraData
        ),
        /*
		coApplicant:  gets filled up with a condition look at the bottom
		guarantor: { // optional
			relationship: 'Self',
			salutation: 'Mr.',
			firstName: 'Earnest',
			lastName: 'Winhoffer',
			middleName: 'T',
			suffix: 'JR',
			phone: '5167349659',
			ssn: '788999009',
			dateOfBirth: '1963-06-18',
			address: {
				line1: '8374 Test St',
				line2: 'APT #D19',
				city: 'Littleton',
				state: 'CO',
				postalCode: '80128',
			},
			driversLicenseNumber: '784534987',
			driversLicenseState: 'CO',
			monthsAtCurrentAddress: 18,
			otherPhone: '5167349659',
			email: 'test_email@testserver.com',
			preferredContactMethod: 'Email',
			preferredLanguage: 'English',
			currentEmployment: {
				employerName: 'Boyles Test Co.',
				totalMonthsEmployed: 36,
				occupation: 'Program Manager',
				workPhone: '7130780239',
				status: 'Employed',
				employerAddress: {
					line1: '8374 Test St',
					line2: 'APT #D19',
					city: 'Littleton',
					state: 'CO',
					postalCode: '80128',
				},
			},
			income: 0,
			incomeFrequency: 'Monthly',
			otherMonthlyIncome: 104,
			otherMonthlyIncomeSource: 'Pension',
			housingStatus: 'Rent',
			maritalStatus: 'Married',
			mortgageOrRentAmount: 3500.89,
			previousAddress: {
				line1: '8374 Test St',
				line2: 'APT #D19',
				city: 'Littleton',
				state: 'CO',
				postalCode: '80128',
			},
			monthsAtPreviousAddress: 18,
			previousEmployment: {
				employerName: 'Boyles Test Co.',
				totalMonthsEmployed: 36,
				occupation: 'Program Manager',
				workPhone: '7130780239',
				status: 'Employed',
				employerAddress: {
					line1: '8374 Test St',
					line2: 'APT #D19',
					city: 'Littleton',
					state: 'CO',
					postalCode: '80128',
				},
			},
			spouse: {
				firstName: 'Earnest',
				lastName: 'Winhoffer',
				middleName: 'T',
				suffix: 'JR',
				address: {
					line1: '8374 Test St',
					line2: 'APT #D19',
					city: 'Littleton',
					state: 'CO',
					postalCode: '80128',
				},
				income: 0,
				incomeFrequency: 'Monthly',
				otherMonthlyIncome: 1000,
				otherMonthlyIncomeSource: 'Rent',
			},
			references: [
				{
					salutation: 'Mr.',
					firstName: 'Earnest',
					lastName: 'Winhoffer',
					middleName: 'T',
					suffix: 'JR',
					phone: '5167349659',
					ssn: '788999009',
					dateOfBirth: '1963-06-18',
					address: {
						line1: '8374 Test St',
						line2: 'APT #D19',
						city: 'Littleton',
						state: 'CO',
						postalCode: '80128',
					},
				},
			],
			consentGiven: true,
		},
		driver: { // optional
			salutation: 'Mr.',
			firstName: 'Earnest',
			lastName: 'Winhoffer',
			middleName: 'T',
			suffix: 'JR',
			phone: '5167349659',
			ssn: '788999009',
			dateOfBirth: '1963-06-18',
			address: {
				line1: '8374 Test St',
				line2: 'APT #D19',
				city: 'Littleton',
				state: 'CO',
				postalCode: '80128',
			},
		},
		vehicle: gets filled up with a condition look at the bottom
		tradeIns: [
			{
				inventoryVehicleCondition: 'Used',
				vin: '2T1BURHE0JC981147',
				stockNumber: '181425AN',
				chromeYear: 2017,
				chromeMake: 'Toyota',
				chromeModel: 'Corolla',
				chromeStyle: '4dr Reg WB Plus 1SB Pkg',
				chromeMakeId: 12,
				chromeModelId: 125,
				chromeStyleId: 125534,
				otherYear: 2017,
				otherMake: 'Toyota',
				otherModel: 'Corolla',
				otherTrim: '4dr Reg WB Plus 1SB Pkg',
				otherOptions: 'Pwr Sunroof, Wheels',
				certifiedUsed: true,
				tradeInType: 'Finance',
				actualCashValue: 15000,
				monthlyPayment: 250,
				payoffAmount: 18000,
				allowanceAmount: 1880,
				tradeInLicensePlateNumber: 'TTH-1234',
				vehicleBookCondition: 'VeryGood',
				lienHolder: {
					name: 'Ford Motor Credit Co',
					phone: '336-968-4645',
					address: {
						line1: '8374 Test St',
						line2: 'APT #D19',
						city: 'Littleton',
						state: 'CO',
						postalCode: '80128',
					},
				},
			},
		],
		financeSummary: gets filled up with a condition look at the bottom
		lenderList: [
			{
				lenderId: 'BOA',
				lenderName: 'Bank of America',
			},
		],
		partnerSource: '', // 'string' optional
		comments: '', // optional, 'Credit Application comments to the lender. Customer needs to get confirmation from his wife.',
	*/
        communityPropertyDisclosureIndicator: getCommunityPropertyDisclosureIndicator(creditAppState),
        regulationBIndicator:
            applicantHasRegulationBIndicator(creditAppState) && creditAppState.reviewInfo.regulationBIndicator.value === 'true',
        privacyNoticeIndicator: creditAppState.reviewInfo.privacyNoticeIndicator.value === 'true'
        /*
		true  property state, in which, property acquired by either spouse during a marriage is considered community property
		extraData: [ //optional
			{
				name: 'OTDXDCMB01',
				value: 'CustomValue-SOJCD165',
			},
		],
		*/
    };

    if (applicantChoice === APPLICANT_CHOICE.JOINT) {
        (jsonPayload as any).coApplicant = generateApplicantPayload(
            personalInfo.jointApplicant,
            housingInfo.jointApplicant,
            employmentInfo.jointApplicant,
            reviewInfo,
            extraData
        );
    }
    return jsonPayload;
};

export { generatePayload, generateApplicantPayload };
