/* eslint-disable no-extra-boolean-cast */
import * as dateUtils from '@makemydeal/dr-common-utils/dist/helpers/dateUtils';

import { InfoCardData } from '../pages/Interfaces';
import { VehicleSectionTitles } from '../types/Constants';
import { KeyValuePair } from './../types/KeyValuePair';
import { getLambdaUrl } from './externalUrls';

declare global {
    interface Window {
        /* eslint-disable-next-line camelcase */
        drs__SpGlobalConfig: any;
    }
}

export const isLocal =
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/);

export const getEnv = (): string => {
    let domain: string;
    // Does the env exist in SP context?
    if (window.drs__SpGlobalConfig) {
        domain = window.drs__SpGlobalConfig.environment;
    } else {
        const domainFromUrl: string = window.location.href.split(/\//)[2];
        const domainPrefix: string = domainFromUrl.split(/\./)[0];
        domain = domainPrefix.split(/-/)[1];
        if (domain === undefined && domainPrefix.includes('localhost')) return 'local';
        if (domain === undefined && domainPrefix === 'secure') return 'production';
    }
    return domain;
};

export const isProduction = getEnv() === 'production';

export const getClientConfig = async (params: KeyValuePair, initData?: any) => {
    let theme = params.theme;
    let initUrl = getLambdaUrl();
    initUrl = initUrl.concat(`?sponsor=${params.sponsor}`);
    initUrl = initUrl.concat(`&dealerId=${params.dealerId}`);
    initUrl = initUrl.concat(`&leadRoutingOverrideEnabled=${params.leadRoutingOverrideEnabled}`);
    initUrl = initUrl.concat(`&dealertrackOverrideId=${sanitizeStrNum(params.dealertrackOverrideId)}`);
    if (!!params.enableToggles) initUrl = initUrl.concat(`&enableToggles=${params.enableToggles}`);
    if (!!params.disableToggles) initUrl = initUrl.concat(`&disableToggles=${params.disableToggles}`);
    if (!emptyString(params.sessionId)) initUrl = initUrl.concat(`&sessionId=${params.sessionId}`);
    if (!emptyString(params.dealXgId)) initUrl = initUrl.concat(`&dealXgId=${params.dealXgId}`);
    if (!emptyString(params.spAssetsVersion)) initUrl = initUrl.concat(`&spAssetsVersion=${params.spAssetsVersion}`);
    if (!emptyString(params.sourcePartnerId)) initUrl = initUrl.concat(`&sourcePartnerId=${params.sourcePartnerId}`);
    if (!emptyString(params.sourcePartnerDealerId))
        initUrl = initUrl.concat(`&sourcePartnerDealerId=${params.sourcePartnerDealerId}`);

    if (!emptyString(params.dealerPhone)) initUrl = initUrl.concat(`&dealerPhone=${params.dealerPhone}`);
    if (!emptyString(params.dealerName)) initUrl = initUrl.concat(`&dealerName=${params.dealerName}`);

    if (initData) {
        theme = initData.dealer?.themeRedesign || initData.dealer?.theme || params.theme;
        const spVersion = params.spVersion || (await (initData?.['sp-version'] || (await getShopperVersion())));
        const themePath = process.env.REACT_APP_THEME_PATH || generateShopperThemePath(theme, spVersion);
        const fontPath = theme ? themePath.replace('.json', '-main.css') : 'default-fonts.css';
        return {
            initUrl,
            themePath,
            fontPath,
            ...initData
        };
    } else {
        return { initUrl, ...params };
    }
};

// This is a fallback function, we'll need to take it out
// when we can ensure the sp-version gets fetched from external resources.
export const getShopperVersion = () => {
    let url;
    const env = getEnv();
    switch (env) {
        case 'local': {
            url = 'https://dev.shop.dealer.com/sp/bff/echo';
            break;
        }
        case 'production': {
            url = 'https://shop.dealer.com/sp/bff/echo';
            break;
        }
        default: {
            url = `https://${env}.shop.dealer.com/sp/bff/echo`;
        }
    }
    return fetch(url)
        .then((response) => response.json())
        .then((data) => data.meta.appVersion);
};
export const generateShopperThemePath = (theme = 'default', version: string) => {
    // try to detect the sp version from the global config if it exists
    if (!version || version === '') {
        if (window.drs__SpGlobalConfig && window.drs__SpGlobalConfig.allThemesJson) {
            const globalThemePath: string | any = Object.values(window.drs__SpGlobalConfig.allThemesJson)[0];
            const dynamicVersion = globalThemePath.match(/[\d]+[.][\d]+[.][\d]+/)[0];
            version = dynamicVersion;
        }
    }
    const brandedThemes = [
        'hyundai',
        'subaru',
        'kbb',
        'atc',
        'cjdr',
        'ford',
        'audi',
        'lexus',
        'bmw',
        'nissan',
        'volvo',
        'gm',
        'buick',
        'chevrolet',
        'gmc',
        'toyota',
        'jaguar',
        'land-rover',
        'vw',
        'kia',
        'mazda',
        'acura',
        'mitsubishi',
        'porsche',
        'infiniti',
        'maserati',
        'cadillac',
        'lincoln',
        'buick-gmc',
        'alfa-romeo',
        'mini',
        'mercedes',
        'genesis'
    ];

    let url: string;
    const env = getEnv();
    const brandPrefix = brandedThemes.includes(theme) ? 'brand-' : '';
    const polishedTheme = theme.includes('.css') ? theme.replace('.css', '') : theme;
    if (env === 'local') {
        // eslint-disable-next-line max-len
        url = `https://dev.shop.dealer.com/static/sp/${version}/css/${brandPrefix}${polishedTheme}/theme-${brandPrefix}${polishedTheme}.json`;
    } else {
        // use a relative path here so we don't care about the environment
        url = `/static/sp/${version}/css/${brandPrefix}${polishedTheme}/theme-${brandPrefix}${polishedTheme}.json`;
    }
    return url;
};

export const isEmptyObject = (obj?: object): boolean => {
    return obj === null || obj === undefined || Object.keys(obj).length === 0;
};

export const emptyString = (str: string | undefined | null): boolean => {
    return !str || !/[^\s]+/.test(str);
};

export const sanitizeStrNum = (str: string): string | undefined => {
    if (str) return str.replace(/\D/g, '');
};

export const upperCaseFirst = (str: string | undefined | null): string => {
    if (emptyString(str)) return '';
    return `${str?.charAt(0).toUpperCase()}${str?.slice(1)}`;
};

export const totalMonth = (year: string | undefined, months: string | undefined): number => {
    const yearNumber = emptyString(year) ? 0 : Number(year?.trim());
    const monthsNumber = emptyString(months) ? 0 : Number(months?.trim());
    return yearNumber * 12 + monthsNumber;
};
export const twoYearsOrLess = (year: string | undefined, months: string | undefined): boolean =>
    totalMonth(year, months) > 0 && totalMonth(year, months) <= 24;

/**
 * @param birthDate Format: MM/DD/YYYY
 */
export const calculateAge = (birthDate: string): number => {
    const bDate = new Date(birthDate);
    const current = new Date();

    let years = current.getFullYear() - bDate.getFullYear();

    if (current.getMonth() < bDate.getMonth() || (current.getMonth() === bDate.getMonth() && current.getDate() < bDate.getDate())) {
        years--;
    }
    return years;
};

export const calculateAgeMonths = (birthDate: string): number => {
    const bDate = new Date(birthDate);
    const current = new Date();

    let years = current.getFullYear() - bDate.getFullYear();
    let months = 0;

    if (current.getMonth() < bDate.getMonth() || (current.getMonth() === bDate.getMonth() && current.getDate() < bDate.getDate())) {
        years--;
        months = 12 + (current.getMonth() - bDate.getMonth());
        if (current.getDate() < bDate.getDate()) {
            months--;
        }
    } else {
        months = current.getMonth() - bDate.getMonth();
        if (current.getDate() < bDate.getDate()) {
            months--;
        }
    }

    return years * 12 + months;
};

/**
 *  i :: { 0: "0", 1: "1" , .... i: "i"}
 * @param length
 */
export const uniqueKeyValuePairs = (length: number): KeyValuePair => {
    if (length > -1) {
        return Array.from({ length }).reduce((acc: any, a, i) => ({ ...acc, [i.toString()]: i.toString() }), {}) as {
            [x: string]: string;
        };
    } else return {};
};

export const findKeyFromValue = (value: string | undefined, category: KeyValuePair): string | undefined =>
    Object.keys(category).find((key: string) => category[key] === value);

export const GetNumberFromString = (value: string, defaultValue = 0): number => {
    return Number(emptyString(value) ? defaultValue : value);
};

export const GetRoundNumberFromString = (value: string, defaultValue = 0): number => {
    return Math.ceil(Number(emptyString(value) ? defaultValue : value));
};

export { dateUtils };

export const xml2json = (srcDOM: any) => {
    const children = [...srcDOM.children];
    // base case for recursion.
    if (!children.length) {
        return srcDOM.innerHTML;
    }
    // initializing object to be returned.
    const jsonResult: any = {};

    for (const child of children) {
        // checking is child has siblings of same name.
        const childIsArray = children.filter((eachChild) => eachChild.nodeName === child.nodeName).length > 1;

        // if child is array, save the values as array, else as strings.
        if (childIsArray) {
            if (jsonResult[child.nodeName] === undefined) {
                jsonResult[child.nodeName] = [xml2json(child)];
            } else {
                jsonResult[child.nodeName].push(xml2json(child));
            }
        } else {
            jsonResult[child.nodeName] = xml2json(child);
        }
    }

    return jsonResult;
};

export const getAccelerateDealerId = (
    dealerId: number,
    creditProvider: string,
    leadRoutingOverrideEnabled: boolean,
    dealertrackOverrideId: number
): number => {
    if (leadRoutingOverrideEnabled && creditProvider !== 'external') {
        return dealertrackOverrideId; // Dealer with Legacy MMD Dealer Id
    } else {
        return dealerId;
    }
};

export const setNewRelicCustomAttributes = (attr: { [key: string]: any } = {}) => {
    const { NREUM } = window as any;

    if (typeof NREUM?.setCustomAttribute === 'function') {
        Object.entries(attr).forEach(([key, value]) => {
            NREUM.setCustomAttribute(key, value);
        });
    }
};

export const buildVehicleSectionData = (key: VehicleSectionTitles, data: any): InfoCardData | undefined => {
    let result;
    if (!data || typeof data !== 'object' || (data && !Object.keys(data).length)) return result;
    switch (key) {
        case VehicleSectionTitles.VEHICLE:
            {
                const { vin, year, make, model, trim, condition } = data;
                result = {
                    vin: { label: 'VIN', value: vin || '' },
                    condition: { label: 'Condition', value: condition || '' },
                    year: { label: 'Year', value: year?.toString() || '' },
                    make: { label: 'Make', value: make || '' },
                    model: { label: 'Model', value: model || '' },
                    trim: { label: 'Trim', value: trim || '' }
                };
            }
            break;

        case VehicleSectionTitles.TRADE_IN:
            {
                const { mileage, zipCode, value, year, make, model, trim, condition } = data;
                result = {
                    year: { label: 'Year', value: year?.toString() || '' },
                    make: { label: 'Make', value: make || '' },
                    model: { label: 'Model', value: model || '' },
                    trim: { label: 'Trim', value: trim || '' },
                    condition: { label: 'Condition', value: condition || '' },
                    mileage: { label: 'Mileage', value: numberWithCommas(mileage) },
                    zipCode: { label: 'Zip Code', value: zipCode || '' },
                    estTradeInValue: { label: 'Est. Trade-in Value', value: formatPrice(value) }
                };
            }
            break;
        case VehicleSectionTitles.FINANCE:
            {
                const { amountFinanced, netCashDown, msrp } = data;
                result = {
                    msrp: { label: 'MSRP', value: formatPrice(msrp) || '' },
                    cashDown: { label: 'Cash Down', value: formatPrice(netCashDown) || '' },
                    amountFinanced: { label: 'Est. Total Financed', value: formatPrice(amountFinanced) || '' }
                };
            }
            break;
        default:
            break;
    }
    return result;
};

export const formatPrice = (amt: string | number): string => {
    return (
        amt?.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
        }) || ''
    );
};

export const numberWithCommas = (num: string | number): string => {
    return num?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') || '';
};

export const allowOnlyNumbers = (value: string): string => {
    return value ? value.replace(/[^0-9]/g, '') : '';
};

export const mapSuffixToOptions = (suffix: string): string => {
    const juniorMappings = new Set(['JR', 'Jr.', 'Jr', 'Junior', 'junior', 'JR.']);
    const seniorMappings = new Set(['SR', 'Sr.', 'Sr', 'Senior', 'senior', 'sr']);
    if (juniorMappings.has(suffix)) {
        return 'JR';
    } else if (seniorMappings.has(suffix)) {
        return 'SR';
    }
    return suffix;
};
